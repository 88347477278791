export const FormsEditForm = () => import('../..\\components\\global\\forms\\EditForm.vue' /* webpackChunkName: "components/forms-edit-form" */).then(c => wrapFunctional(c.default || c))
export const FormsSubEditForm = () => import('../..\\components\\global\\forms\\SubEditForm.vue' /* webpackChunkName: "components/forms-sub-edit-form" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../..\\components\\Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../..\\components\\Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const VuetifyLogo = () => import('../..\\components\\VuetifyLogo.vue' /* webpackChunkName: "components/vuetify-logo" */).then(c => wrapFunctional(c.default || c))
export const AsyncComponentsErrorComponent = () => import('../..\\components\\asyncComponents\\ErrorComponent.vue' /* webpackChunkName: "components/async-components-error-component" */).then(c => wrapFunctional(c.default || c))
export const AsyncComponentsForbiddenPage = () => import('../..\\components\\asyncComponents\\ForbiddenPage.vue' /* webpackChunkName: "components/async-components-forbidden-page" */).then(c => wrapFunctional(c.default || c))
export const AsyncComponentsLoadingComponent = () => import('../..\\components\\asyncComponents\\LoadingComponent.vue' /* webpackChunkName: "components/async-components-loading-component" */).then(c => wrapFunctional(c.default || c))
export const LayoutsNavBar = () => import('../..\\components\\layouts\\NavBar.vue' /* webpackChunkName: "components/layouts-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const LayoutsSavingSnackbar = () => import('../..\\components\\layouts\\SavingSnackbar.vue' /* webpackChunkName: "components/layouts-saving-snackbar" */).then(c => wrapFunctional(c.default || c))
export const LayoutsSnackbar = () => import('../..\\components\\layouts\\Snackbar.vue' /* webpackChunkName: "components/layouts-snackbar" */).then(c => wrapFunctional(c.default || c))
export const WebportalFooter = () => import('../..\\components\\webportal\\Footer.vue' /* webpackChunkName: "components/webportal-footer" */).then(c => wrapFunctional(c.default || c))
export const WebportalNavbar = () => import('../..\\components\\webportal\\Navbar.vue' /* webpackChunkName: "components/webportal-navbar" */).then(c => wrapFunctional(c.default || c))
export const AsyncComponentsUsersUserCards = () => import('../..\\components\\asyncComponents\\users\\UserCards\\Cards.vue' /* webpackChunkName: "components/async-components-users-user-cards" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
