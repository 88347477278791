import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _43106573 = () => interopDefault(import('..\\pages\\about.vue' /* webpackChunkName: "pages/about" */))
const _1a3f47e8 = () => interopDefault(import('..\\pages\\about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _41ca8aad = () => interopDefault(import('..\\pages\\admin\\index.vue' /* webpackChunkName: "pages/admin/index" */))
const _37a01375 = () => interopDefault(import('..\\pages\\azure.vue' /* webpackChunkName: "pages/azure" */))
const _5deb2054 = () => interopDefault(import('..\\pages\\bintan-island\\index.vue' /* webpackChunkName: "pages/bintan-island/index" */))
const _57484d48 = () => interopDefault(import('..\\pages\\dashboard\\index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _2c6fe34f = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _b913a302 = () => interopDefault(import('..\\pages\\roles\\index.vue' /* webpackChunkName: "pages/roles/index" */))
const _6b21cb68 = () => interopDefault(import('..\\pages\\services.vue' /* webpackChunkName: "pages/services" */))
const _21677220 = () => interopDefault(import('..\\pages\\species\\index.vue' /* webpackChunkName: "pages/species/index" */))
const _c19f7b58 = () => interopDefault(import('..\\pages\\users\\index.vue' /* webpackChunkName: "pages/users/index" */))
const _20a99e12 = () => interopDefault(import('..\\pages\\users\\profile\\index.vue' /* webpackChunkName: "pages/users/profile/index" */))
const _62c84038 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _432906f0 = () => interopDefault(import('..\\pages\\species\\_id.vue' /* webpackChunkName: "pages/species/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _43106573,
    name: "about"
  }, {
    path: "/about-us",
    component: _1a3f47e8,
    name: "about-us"
  }, {
    path: "/admin",
    component: _41ca8aad,
    name: "admin"
  }, {
    path: "/azure",
    component: _37a01375,
    name: "azure"
  }, {
    path: "/bintan-island",
    component: _5deb2054,
    name: "bintan-island"
  }, {
    path: "/dashboard",
    component: _57484d48,
    name: "dashboard"
  }, {
    path: "/login",
    component: _2c6fe34f,
    name: "login"
  }, {
    path: "/roles",
    component: _b913a302,
    name: "roles"
  }, {
    path: "/services",
    component: _6b21cb68,
    name: "services"
  }, {
    path: "/species",
    component: _21677220,
    name: "species"
  }, {
    path: "/users",
    component: _c19f7b58,
    name: "users"
  }, {
    path: "/users/profile",
    component: _20a99e12,
    name: "users-profile"
  }, {
    path: "/",
    component: _62c84038,
    name: "index"
  }, {
    path: "/species/:id",
    component: _432906f0,
    name: "species-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
